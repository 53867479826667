input[name="transaction"] ~ label {
  padding: 8px 0;
  border-radius: 5px;
  text-align: center;
  color: var(--chakra-colors-grey-main);
  font-weight: 500;
  font-size: 14px;
}
input[name="transaction"]:checked + label {
  background-color: var(--chakra-colors-white-100);
  color: var(--chakra-colors-black-main);
}

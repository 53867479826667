@import "../../styles/global.scss";

.slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $black;

  img {
    width: 70%;

    object-fit: cover;
  }

  h2 {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 28px;
  }

  p {
    font-size: 14px;
    color: $dark-grey;
    padding: 0 40px;
  }
}

@media screen and (min-width: 450px) {
  .slide {
    img {
      width: 50%;
    }
  }
}

@import "./styles/global.scss";

* {
  box-sizing: border-box;
}

body {
  color: "var(--chakra-colors-black-main)";
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn {
  padding: 15px 24px;
  border-radius: 20px;
  min-width: 111px;
  font-weight: 600;
}

.btn--primary {
  background-color: $primary;
  color: $white;
}
.btn--secondary {
  background-color: $secondary;
  color: $primary;
  border-color: $secondary;
}

.btn--clear {
  background-color: $white;
  color: $primary;
  border-color: $primary;
  border-width: 1px;
}

.btn--disabled {
  background-color: $primary;
  opacity: 0.5;
  color: $white;
}
.btn--normal {
  width: 100%;
}

.transaction--receive {
  background-color: var(--chakra-colors-blue-main);
}
.transaction--withdraw {
  background-color: var(--chakra-colors-red-main);
}
.transaction--deposit {
  background-color: var(--chakra-colors-green-150);
  color: var(--chakra-colors-green-100);
}

.mantine-Carousel-indicators,
.mantine-19swgkw {
  position: static;
  margin: 20px 0;
}

.error {
  color: red;
  font-size: 14px;
}

@import "../../styles/global.scss";

header {
  color: "#676666";
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  z-index: 10;
  background-color: $white;
}

.nav-item {
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.nav-icon {
  font-size: 20px;
  color: inherit;
}

.arrow {
  display: none;
}

@media screen and (min-width: 768px) {
  header {
    top: 0;
    left: 0;
    height: 100%;
    width: fit-content;
    display: block;
    padding: 40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    z-index: 1000;
    // background-color: #fff;
  }
  .nav-item {
    flex-direction: row;
    width: 100%;
    padding: 10px 0px;
    margin: 15px auto;
    justify-content: flex-start;
  }

  .nav-icon {
    margin-right: 16px;
  }

  .arrow {
    display: block;
    margin-left: auto;
  }
}

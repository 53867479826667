

  .custom-range-input {
    width: 100%;
    cursor: pointer;
  }

  .custom-range-input::-webkit-slider-thumb::after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #f0c608;
    border-radius: 50%;
  }

  .custom-range-input::-moz-range-thumb::after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #f0c608;
    border-radius: 50%;
  }

  .custom-range-input::-ms-thumb::after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #f0c608;
    border-radius: 50%;
  }

  /* Hide the default thumb indicator */
  .custom-range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
  }

  .custom-range-input::-moz-range-thumb {
    appearance: none;
  }

  .custom-range-input::-ms-thumb {
    appearance: none;
  }

  .scrollable-container {
    /* Hide the scrollbar */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent; /* For Firefox */
  }
  
  .scrollable-container::-webkit-scrollbar {
    width: 0.5em;
    background-color: transparent;
  }
  
  .scrollable-container::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

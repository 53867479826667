// tr {
//   width: 100%;
//   gap: 5;
// }
thead {
  border-bottom: 1px solid #d3d3d3;
}

th {
  font-weight: 400;
  color: var(--chakra-colors-grey-dark);
}
td {
  font-weight: 500;
  color: var(--chakra-colors-black-main);
}

th,
td {
  padding: 15px;
}

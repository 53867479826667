/**************************** COLORS ****************************/

$primary: rgba(76, 176, 81, 1);
$secondary: rgba(76, 176, 81, 0.2);
$black: rgba(3, 2, 2, 1);
$light-black: rgba(45, 45, 45, 1);
$grey: rgba(136, 136, 136, 1);
$medium-grey: rgba(253, 255, 253, 1);
$dark-grey: rgba(103, 102, 102, 1);
$white: rgba(253, 255, 253, 1);
$light-white: rgba(226, 224, 224, 1);
$yellow: rgba(251, 187, 0, 1);

/**************************** ELEMENTS  ************************/

%h1-bold {
  font-size: 34px;
  font-family: 700;
}

h1 {
  font-size: 34px;
  font-weight: 500;
}

h2 {
  font-size: 32px;
  font-weight: 500;
}
h3 {
  font-size: 24px;
  font-weight: 600;
}
h4 {
  font-size: 20px;
  font-weight: 600;
}

.input-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: var(--chakra-colors-black-main);
  display: block;
}
.input-sub-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.004em;
  color: var(--chakra-colors-grey-main);
  display: block;
}

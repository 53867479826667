.modal_overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(11, 11, 11, 0.5);

  @media screen and (min-width: 768px) {
    // max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    // padding-left: "210px";
  }
}

.slide {
  width: 100%;
  height: 200px;
  background-color: white;
  position: absolute;
  bottom: -300px;
  z-index: 111;
  transition: 350ms;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.cover {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(20, 20, 20, 0.465);
  top: 0px;
  display: none;
  transition: 350ms;
}

.popup {
  position: absolute;
  right: 20px;
  top: 50px;
}
.upload {
  width: 100%;
  height: 200px;
  border: 1px dashed gray;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.radio_option {
  display: flex;
  label {
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 20px;
  }

  input {
    margin-left: auto;
  }
}

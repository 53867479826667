@import "../../styles/global.scss";

.head {
  color: $primary;
}

.indicator {
  &.data-active {
    background-color: $yellow;
  }
}

.react-multi-carousel > .react-multi-carousel-dot--active {
  button {
    background-color: "rgba(251, 187, 0, 1)";
  }
}

.mantine-Carousel-indicators,
.mantine-19swgkw {
  position: static;
}
// width: rem(8),
// height: rem(8),
// backgroundColor: "#D9D9D9",
// transition: "width 250ms ease",

// "&[data-active]": {
//   width: rem(27),
//   backgroundColor: "rgba(251, 187, 0, 1)",
// },
